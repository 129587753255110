<template>

  <v-card class="pt-3 pl-5 elevation-0">

      <v-card-text>
        The sync to Google and Bing accounts takes place every hour from 06:45 CET until 22:45 CET each weekday, and takes c. 5 minutes.
      </v-card-text>

      <v-tabs v-model="tab" @change="tabChanged">
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item class="pt-5">
          <v-row class="inner-content">
            <v-col>
              <NKWSyncLogTable v-if="tab == 0" :synced="true" />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item class="pt-5">
          <v-row class="inner-content">
            <v-col>
              <NKWSyncErrorsTable v-if="tab == 1" />
            </v-col>
          </v-row>
        </v-tab-item>

      </v-tabs-items>

  </v-card>

</template>

<script>
export default {
  name: 'NKWSync',

  data () {
    return {
      tab: null,
      items: ['Log', 'Errors'],
      lsTab: 'nkwSync_tab',
    }
  },

  components: {
    NKWSyncErrorsTable: () => import('@/components/tables/NKWSyncErrorsTable.vue'),
    NKWSyncLogTable: () => import('@/components/tables/NKWSyncLogTable.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>
